import _to_consumable_array from "@swc/helpers/src/_to_consumable_array.mjs";
var FALLBACK_URL = "https://staging.finn.com";
export var getTestUrl = function() {
    var url = process.env.ENVIRONMENT_URL || FALLBACK_URL;
    if (url[url.length - 1] === "/") {
        return url.slice(0, -1);
    }
    return url;
};
export var getDomainForCookie = function(url) {
    return url === null || url === void 0 ? void 0 : url.replace("https://", "").replace(":3001", "");
};
var commonTestCookies = [
    {
        name: "E2E-test-in-progress",
        value: "yes",
        path: "/",
        domain: getDomainForCookie(getTestUrl())
    },
    {
        name: "E2E-disable-direct-checkout",
        value: "yes",
        path: "/",
        domain: getDomainForCookie(getTestUrl())
    },
    {
        name: "en-US_fullstory",
        value: "disabled",
        path: "/",
        domain: getDomainForCookie(getTestUrl())
    },
    {
        name: "de-DE_fullstory",
        value: "disabled",
        path: "/",
        domain: getDomainForCookie(getTestUrl())
    },
    {
        name: "e2e-secret",
        value: process.env.SECRET_E2E_COOKIE || "",
        path: "/",
        domain: getDomainForCookie(getTestUrl())
    }, 
];
var getCookiesForFeatures = function(locale, disabledFeatures, enabledFeatures) {
    var featureCookies = [];
    disabledFeatures.forEach(function(feature) {
        featureCookies.push({
            name: "".concat(locale, "_").concat(feature),
            value: "a",
            path: "/",
            domain: getDomainForCookie(getTestUrl())
        });
    });
    (enabledFeatures || []).forEach(function(feature) {
        featureCookies.push({
            name: "".concat(locale, "_").concat(feature),
            value: "b",
            path: "/",
            domain: getDomainForCookie(getTestUrl())
        });
    });
    return featureCookies;
};
var disabledFeaturesDE = [
    "exp-pcpdropdown2",
    "exp-plp-grid-v1",
    "exp-hp-heroswap",
    "exp-pdp-carinfo", 
];
var forceBVariantFeaturesDE = [];
export var getCookiesForFeaturesByLocale = function(locale, disabledFeatures, enabledFeatures) {
    return getCookiesForFeatures(locale, disabledFeatures, enabledFeatures);
};
export var getTestCookies = function(locale) {
    var features = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : [];
    return _to_consumable_array(commonTestCookies).concat(_to_consumable_array(getCookiesForFeaturesByLocale(locale, disabledFeaturesDE.concat(features), forceBVariantFeaturesDE)));
};
