export var TrackingEventName;
(function(TrackingEventName) {
    TrackingEventName["PRODUCT_LIST_VIEWED"] = "Product List Viewed";
    TrackingEventName["PRODUCT_LIST_FILTERED"] = "Product List Filtered";
    TrackingEventName["PRODUCT_CLICKED"] = "Product Clicked";
    TrackingEventName["PRODUCT_VIEWED"] = "Product Viewed";
    TrackingEventName["PRODUCT_ADDED"] = "Product Added";
    TrackingEventName["CHECKOUT_STARTED"] = "Checkout Started";
    TrackingEventName["POSITIVE_LEAD"] = "Positive Lead";
    TrackingEventName["CHECKOUT_LEAD"] = "Lead";
    TrackingEventName["CHECKOUT_STEP_VIEWED"] = "Checkout Step Viewed";
    TrackingEventName["CHECKOUT_STEP_COMPLETED"] = "Checkout Step Completed";
    TrackingEventName["PAYMENT_METHOD_SELECTED"] = "Payment Method Selected";
    TrackingEventName["PAYMENT_INFO_ENTERED"] = "Payment Info Entered";
    TrackingEventName["PAYMENT_PROCESSING_ERROR"] = "Payment Processing Error";
    TrackingEventName["ORDER_COMPLETED"] = "Order Completed";
    TrackingEventName["COUPON_ENTERED"] = "Coupon Entered";
    TrackingEventName["COUPON_APPLIED"] = "Coupon Applied";
    TrackingEventName["COUPON_DENIED"] = "Coupon Denied";
    TrackingEventName["COUPON_REMOVED"] = "Coupon Removed";
    TrackingEventName["DELIVERY_DATE_SELECTED"] = "Delivery Date Selected";
    TrackingEventName["CONTACT_US_FORM_SUBMISSION"] = "Contact Form Submitted";
    TrackingEventName["CONTACT_UPDATED"] = "Contact Updated";
    TrackingEventName["B2B_FORM_SUBMISSION"] = "B2BFormSubmission";
    TrackingEventName["B2B_LEAD_GEN_FORM_SUBMISSION"] = "Inbound - LeadGen Landing Page Form";
    TrackingEventName["USER_BEHAVIOUR"] = "user-behaviour";
    TrackingEventName[// Gallery Interactions
    "GALLERY_IMAGE_CLICKED"] = "Gallery Image Clicked";
    TrackingEventName["GALLERY_SWIPED"] = "Gallery Swiped";
    TrackingEventName["GALLERY_CLOSED"] = "Gallery Closed";
    TrackingEventName["GALLERY_IMAGE_ZOOMED"] = "Gallery Image Zoomed";
    TrackingEventName["USER_ACCOUNT"] = "UserAccount";
    TrackingEventName["REFERRAL_VOUCHER"] = "Referral Voucher Applied";
    TrackingEventName["PDP_INTERACTION"] = "PDP Interaction";
    TrackingEventName["Error"] = "error";
    TrackingEventName["CHECKOUT_ERROR"] = "Checkout Error Occurred";
    TrackingEventName["REFERRAL_MODAL_OPENED"] = "Referral Modal Opened";
    TrackingEventName["REFERRAL_OPTION_CLICKED"] = "Referral Option Clicked";
    TrackingEventName["COMING_SOON_MODAL_OPENED"] = "Coming Soon Modal Opened";
    TrackingEventName["COMING_SOON_LEAD_SUBMITTED"] = "Coming Soon Lead Submitted";
    TrackingEventName["DIRECT_CHECKOUT_MODAL_SHOWN"] = "Direct Checkout Modal Shown";
    TrackingEventName["DIRECT_CHECKOUT_WITHOUT_MODAL"] = "Direct Checkout Without Modal";
    TrackingEventName["DIRECT_CHECKOUT_INELIGIBLE"] = "Direct Checkout Ineligible";
    TrackingEventName["DIRECT_CHECKOUT_MODAL_CLOSED"] = "Direct Checkout Modal Closed";
    TrackingEventName["DIRECT_CHECKOUT_MODAL_ACCEPTED"] = "Direct Checkout Modal Accepted";
    TrackingEventName["DIRECT_CHECKOUT_MODAL_DECLINED"] = "Direct Checkout Modal Declined";
    TrackingEventName["BUTTON_CTA_CLICKED"] = "ButtonCTA Clicked";
    TrackingEventName["NAVITEM_CLICKED"] = "NavItem Clicked";
    TrackingEventName["MOBILE_NAV_OPENED"] = "MobileNav Opened";
    TrackingEventName["MOBILE_NAV_CLOSED"] = "MobileNav Closed";
    TrackingEventName["MOBILE_PORTAL_OPENED"] = "MobilePortal Opened";
    TrackingEventName["MOBILE_PORTAL_CLOSED"] = "MobilePortal Closed";
    TrackingEventName["NEON_CAMPAIGN_VIEWED"] = "NEON Campaign Viewed";
    TrackingEventName["NEON_CAMPAIGN_CLICKED"] = "NEON Campaign Clicked";
    TrackingEventName["CAMPAIGN_CARD_CLICKED"] = "Campaign Card Clicked";
    TrackingEventName["CAMPAIGN_CARD_VIEWED"] = "Campaign Card Viewed";
    TrackingEventName["NEON_VOUCHER_MODULE_VIEWED"] = "NEON Voucher Module Viewed";
    TrackingEventName["NEON_VOUCHER_MODULE_LOGGED_IN"] = "NEON Voucher Module Logged In";
    TrackingEventName["NEON_VOUCHER_MODAL_OPENED"] = "NEON Voucher Modal Opened";
    TrackingEventName["NEON_VOUCHER_MODULE_LINK_CLICKED"] = "NEON Voucher Module Link Clicked";
    TrackingEventName["JOB_AUTO_WHITEPAPER_FORM_SUBMITTED"] = "Whitepaper Inquiry Submitted - JobAuto";
    TrackingEventName["JOB_AUTO_FORM_SUBMITTED"] = "Form Submitted - Job Auto";
    TrackingEventName["ACCOUNT_TYPE_SELECTED"] = "Account Type Selected";
    TrackingEventName["ADDRESS_AUTOCOMPLETE_OPENED"] = "Google Address Autocomplete Opened";
    TrackingEventName["ADDRESS_AUTOCOMPLETE_CLICKED"] = "Google Address Autocomplete Clicked";
    TrackingEventName["ADDRESS_AUTOCOMPLETE_CLOSED"] = "Google Address Autocomplete Closed";
    TrackingEventName["ADDRESS_AUTOCOMPLETE_DISMISSED"] = "Google Address Autocomplete Dismissed";
    TrackingEventName["LEAD_CREATED"] = "Lead Created";
    TrackingEventName["COLOR_SELECTED"] = "Color Selected";
    TrackingEventName["TERM_SELECTED"] = "Term Selected";
    TrackingEventName["DISTANCE_PACKAGE_SELECTED"] = "Distance Package Selected";
    TrackingEventName["RETENTION_CAMPAIGN_CARD_SHOWN"] = "Retention Campaign Card Shown";
    TrackingEventName["RETENTION_CAMPAIGN_CARD_CLICKED"] = "Retention Campaign Card Clicked";
    TrackingEventName["FILTER_CLICKED"] = "Filter Clicked";
    TrackingEventName["VIDEO_STARTED"] = "Video Started";
    TrackingEventName["VIDEO_PAUSED"] = "Video Paused";
    TrackingEventName["VIDEO_FINISHED"] = "Video Finished";
    TrackingEventName["VIDEO_ERROR"] = "Video Error";
    TrackingEventName["VIDEO_CTA_CLICKED"] = "Video CTA Clicked";
    TrackingEventName["VIDEO_REPLAY_CLICKED"] = "Video Replay Clicked";
    TrackingEventName["CTA_CLICKED"] = "CTA Clicked";
    TrackingEventName["TOOLTIP_HOVERED"] = "Tooltip Hovered";
    TrackingEventName["TOOLTIP_LINK_CLICKED"] = "Tooltip Link Clicked";
    TrackingEventName["ESTIMATE_TAX_CLICKED"] = "Estimate Tax Clicked";
    TrackingEventName["COMPARISON_MODAL_SUBMITTED"] = "Comparison Modal Submitted";
    TrackingEventName["COMPARISON_MODAL_FEEDBACK"] = "Comparison Modal Feedback Opened";
    TrackingEventName["COMPARISON_MODAL_CLOSED"] = "Comparison Modal Closed";
    TrackingEventName["LOGIN_CTA_CLICKED"] = "Login CTA Clicked";
    TrackingEventName["REGISTER_CTA_CLICKED"] = "Register CTA Clicked";
    TrackingEventName["ACCOUNT_LOGIN_FAILED"] = "Account Login Failed";
    TrackingEventName["ACCOUNT_CREATION_FAILED"] = "Account Creation Failed";
    TrackingEventName["FORGOT_PASSWORD_CLICKED"] = "Forgot Password Clicked";
    TrackingEventName["LOGIN_MODAL_OPENED"] = "Login Modal Opened";
    TrackingEventName["LOGIN_OPTION_FORM_OPENED"] = "Login Option Form Opened";
    TrackingEventName["LOGIN_FORM_OPENED"] = "Login Form Opened";
    TrackingEventName["LOGIN_MODAL_CLOSED"] = "Login Modal Closed";
    TrackingEventName["REGISTER_MODAL_OPENED"] = "Register Modal Opened";
    TrackingEventName["REGISTER_FORM_OPENED"] = "Register Form Opened";
    TrackingEventName["REGISTER_MODAL_CLOSED"] = "Register Modal Closed";
    TrackingEventName["B2B_LOGIN_CLICKED"] = "B2B Login Clicked";
    TrackingEventName["LOGIN_METHOD_CLICKED"] = "Login Method Clicked";
    TrackingEventName["GOOGLE_ONE_TAP_LOGIN_MESSAGE"] = "Google One Tap Login Message";
    TrackingEventName["CAR_UNAVAILABLE_MODAL_OPENED"] = "Car Unavailable Modal Opened";
    TrackingEventName["CAR_UNAVAILABLE_MODAL_CTA_CLICKED"] = "Car Unavailable Modal CTA Clicked";
    TrackingEventName["BANNER_LINK_CLICKED"] = "Banner Link Clicked";
    TrackingEventName["SOURCE_LINK_CLICKED"] = "Source Link Clicked";
    TrackingEventName["PHONE_SUPPORT_CLICKED"] = "Phone Support Clicked";
    TrackingEventName["HELP_OPTION_CLICKED"] = "Help Option Clicked";
    TrackingEventName["FILTER_CATEGORY_CLICKED"] = "Filter Category Clicked";
    TrackingEventName["FILTER_CATEGORY_CLOSED"] = "Filter Category Closed";
    TrackingEventName["FILTER_OPTION_CLICKED"] = "Filter Option Clicked";
    TrackingEventName["FILTER_DRAWER_OPENED"] = "Filter Drawer Opened";
    TrackingEventName["FILTER_TAG_REMOVED"] = "Filter Tag Removed";
    TrackingEventName["FILTER_TAGS_SCROLLED"] = "Filter Tags Scrolled";
    TrackingEventName["FILTERS_CLEARED"] = "Filters Cleared";
    TrackingEventName["DELIVERY_DATE_SAVED"] = "Delivery Date Saved";
    TrackingEventName["ACCORDION_CLICKED"] = "Accordion Clicked";
    TrackingEventName["SUGGESTED_FAQ_CLICKED"] = "Suggested FAQ Clicked";
    TrackingEventName["FAQ_LINK_CLICKED"] = "FAQ Link Clicked";
    TrackingEventName["DELIVERY_MODAL_CANCELED"] = "Delivery Modal Canceled";
    TrackingEventName["DELIVERY_MODAL_SUBMITTED"] = "Delivery Modal Submitted";
    TrackingEventName["BASKET_CLICKED"] = "Basket Clicked";
    TrackingEventName["BASKET_VIEWED"] = "Basket Viewed";
    TrackingEventName["FINN_LOGO_CLICKED"] = "FINN Logo Clicked";
    TrackingEventName["FORM_VALIDATION_ERROR"] = "Form Validation Error";
    TrackingEventName["LOGIN_NAVBAR_CLICKED"] = "Login NavBar Clicked";
    TrackingEventName["BUSINESS_ACCOUNT_DETECTED"] = "Business Account Detected";
    TrackingEventName["PAGE_CONTROL_CLICKED"] = "Page Control Clicked";
    TrackingEventName["SPECIAL_OFFER_CLICKED"] = "Special Offer Clicked";
    TrackingEventName["SPECIAL_OFFER_FORM_CLOSED"] = "Special Offer Form Closed";
    TrackingEventName["SPECIAL_OFFER_FORM_SUBMITTED"] = "Special Offer Form Submitted";
    TrackingEventName["DEDUCTIBLE_TYPE_CHANGED"] = "Deductible Type Changed";
    TrackingEventName["ACCOUNT_SWITCHED"] = "Account Switched";
    TrackingEventName["FORM_FIELD_FOCUSED"] = "Form Field Focused";
    TrackingEventName["FORM_FIELD_ENTERED"] = "Form Field Entered";
    TrackingEventName["CHECKBOX_CLICKED"] = "Checkbox Clicked";
    TrackingEventName["LINK_CLICKED"] = "Link Clicked";
    TrackingEventName["LOYALTY_ITEM_CLICKED"] = "Loyalty Item Clicked";
    TrackingEventName["LOYALTY_ITEM_CLOSED"] = "Loyalty Item Closed";
    TrackingEventName["LOYALTY_PAGE_OPENED"] = "Loyalty Page Opened";
    TrackingEventName["LOYALTY_INTRO_MODAL_OPENED"] = "Loyalty Intro Modal Opened";
    TrackingEventName["LOYALTY_INTRO_MODAL_CLOSED"] = "Loyalty Intro Modal Closed";
    TrackingEventName["REDEEMABLE_LOYALTY_ITEM_CLICKED"] = "Redeemable Loyalty Item Clicked";
    TrackingEventName["REDEEMABLE_LOYALTY_ITEM_CLOSED"] = "Redeemable Loyalty Item Closed";
    TrackingEventName["REDEEMABLE_LOYALTY_ITEM_REDEEMED"] = "Redeemable Loyalty Item Redeemed";
    TrackingEventName["MODAL_OPENED"] = "Modal Opened";
    TrackingEventName["MODAL_CLOSED"] = "Modal Closed";
    TrackingEventName["DROPDOWN_CLICKED"] = "Dropdown Clicked";
    TrackingEventName["FILTER_DRAWER_CLOSED"] = "Filter Drawer Closed";
    TrackingEventName["INTERLINK_CLICKED"] = "Interlink Clicked";
    TrackingEventName["SWAP_OPTION_CLICKED"] = "Swap Option Clicked";
    TrackingEventName["SWAP_DATE_AUTO_SELECTED_FOR_PROLONGATION"] = "Swap Date Auto Selected For Prolongation";
    TrackingEventName["PROLONGATION_OPTION_CLICKED"] = "Prolongation Option Clicked";
    TrackingEventName["PROLONGATION_MODAL_OPENED"] = "Prolongation Modal Opened";
    TrackingEventName["RETURN_OPTION_CLICKED"] = "Return Option Clicked";
    TrackingEventName["FORGOT_PASSWORD_FORM_OPENED"] = "Forgot Password Form Opened";
    TrackingEventName["FORGOT_PASSWORD_SUCCESS_FORM_OPENED"] = "Forgot Password Success Form Opened";
    TrackingEventName["MAGIC_LINK_FORM_OPENED"] = "Magic Link Form Opened";
    TrackingEventName["MAGIC_LINK_SUCCESS_FORM_OPENED"] = "Magic Link Success Form Opened";
    TrackingEventName["MAGIC_LINK_FORM_SUBMITTED"] = "Magic Link Form Submitted";
    TrackingEventName["MAGIC_LINK_FORM_RE_SUBMITTED"] = "Magic Link Form Re-submitted";
    TrackingEventName["FORGOT_PASSWORD_FORM_SUBMITTED"] = "Forgot Password Form Submitted";
    TrackingEventName["FORGOT_PASSWORD_FORM_RE_SUBMITTED"] = "Forgot Password Form Form Re-submitted";
    TrackingEventName["PLP_CARD_VIEWED"] = "PLP Card Viewed";
    TrackingEventName["STRIPE_EXTERNAL_PROVIDER_REDIRECTED"] = "Stripe External Provider Redirected";
    TrackingEventName["HELPER_OPTION_CLICKED"] = "Helper Option Clicked";
    TrackingEventName["CAR_RECOMMENDATIONS_SHOWN"] = "Car Recommendations Shown";
    TrackingEventName["CAR_RECOMMENDATIONS_CLICKED"] = "Car Recommendations Clicked";
    TrackingEventName["WISHLIST_SYMBOL_SHOWED"] = "Wishlist Symbol Showed";
    TrackingEventName["WISHLIST_SYMBOL_CLICKED"] = "Wishlist Symbol Clicked";
    TrackingEventName["SWAP_OPTION_VIEWED"] = "Swap Option Viewed";
    TrackingEventName["SWAP_OPTION_SELECTED"] = "Swap Option Selected";
    TrackingEventName["GRID_TOGGLE_CLICKED"] = "Grid Toggle Clicked";
})(TrackingEventName || (TrackingEventName = {}));
export var LinkLocation;
(function(LinkLocation) {
    LinkLocation["PDP_BREADCRUMB"] = "PDP Breadcrumb";
    LinkLocation["BLOG_BREADCRUMB"] = "Blog Breadcrumb";
})(LinkLocation || (LinkLocation = {}));
export var NavItem;
(function(NavItem) {
    NavItem["MY_CARS"] = "my_cars";
    NavItem["MY_PROFILE"] = "my_profile";
    NavItem["TECHNICAL_SUPPORT"] = "technical_support";
    NavItem["BONUS_PROGRAM"] = "bonus_program";
    NavItem["MY_DRIVERS"] = "my_drivers";
    NavItem["LOYALTY"] = "loyalty";
    NavItem["LOG_OUT"] = "log_out";
})(NavItem || (NavItem = {}));
