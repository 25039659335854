import { Badge } from '@finn/atoms';
import { ProductOptionCard } from '@finn/platform-modules';
import {
  Features,
  useDownPayment,
  useIsABVariant,
  useIsCVariant,
} from '@finn/ua-featureflags';
import { InfoToolTip } from '@finn/ui-components';
import { calculateTotalPrice, getForBusinessCookie } from '@finn/ui-modules';
import { getFormattedPrice, trackEvent } from '@finn/ui-utils';
import { makeStyles, Theme } from '@material-ui/core';
import React from 'react';
import { useIntl } from 'react-intl';

import { downPaymentOptions, useConfigureStore } from '../ConfigurePageStore';

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    marginTop: theme.spacing(3),
  },
  options: {
    display: 'grid',
    gap: theme.spacing(1),
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    gap: theme.spacing(2),
  },
}));

type DownPaymentOption = {
  withDownpayment: boolean;
  amount: number;
};

type HeaderProps = {
  i18n: ReturnType<typeof useIntl>;
};

const Header: React.FC<HeaderProps> = ({ i18n }) => {
  const isAVariant = useIsABVariant(Features.ExpDownPaymentOptions);
  const isCVariant = useIsCVariant(Features.ExpDownPaymentOptions);
  const isExpDownPaymentOptions = isAVariant || isCVariant;

  return (
    <div className="my-2 flex flex-row items-center gap-2">
      <h6 className="global-t6-semibold">
        {i18n.formatMessage({ id: 'pdp.downPayment.title' })}
      </h6>

      <InfoToolTip
        content={i18n.formatMessage({
          id: `${isExpDownPaymentOptions ? 'pdp.downPayment.tooltipExperiment' : 'pdp.downPayment.tooltip'}`,
        })}
      />
    </div>
  );
};

type OptionListProps = {
  options: number[];
  selectedAmount: number;
  disabled?: boolean;
  onOptionClick: (option: DownPaymentOption) => void;
};

type CalculateFinalPrice = {
  term: number;
  monthlyCost: number;
  downPayment: number;
};

export const calculateFinalPrice = ({
  monthlyCost,
  term,
  downPayment,
}: CalculateFinalPrice): number => {
  const totalSubscriptionPrice = monthlyCost * term;

  const discount = totalSubscriptionPrice * 0.03;

  return (totalSubscriptionPrice - downPayment - discount) / term;
};

const getValidOptions = (
  finalPrice: number,
  options: number[],
  invalidOptionAmount: number
): number[] => {
  return finalPrice > 0
    ? options
    : options.filter((option) => option !== invalidOptionAmount);
};

const OptionList: React.FC<OptionListProps> = ({
  options,
  selectedAmount,
  onOptionClick,
  disabled,
}) => {
  const i18n = useIntl();
  const styles = useStyles();
  const { vehicle, term } = useConfigureStore();
  const isForBusiness = getForBusinessCookie();
  const isCVariant = useIsCVariant(Features.ExpDownPaymentOptions);

  if (!vehicle) return null;

  const maxDownPayment = Math.max(...options);
  const shortestTerm = Math.min(...(vehicle?.available_terms ?? []));

  const monthlyCost = isForBusiness
    ? vehicle.price.available_price_list[`b2b_${shortestTerm}`] ?? 0
    : vehicle.price.available_price_list[`b2c_${shortestTerm}`] ?? 0;

  const finalPrice = calculateFinalPrice({
    monthlyCost,
    term: shortestTerm,
    downPayment: maxDownPayment,
  });

  const validOptions = getValidOptions(finalPrice, options, maxDownPayment);

  const price = calculateTotalPrice({ term, vehicle });

  return (
    <div className={styles.options}>
      {validOptions.map((amount, index) => {
        const downpaymentPrice = calculateTotalPrice({
          term,
          vehicle,
          isDownPayment: true,
          downPaymentAmount: amount,
          isExperimentDownPayment: true,
        });

        const downpaymentDiscount = price - downpaymentPrice;

        const monthlyDiscount = getFormattedPrice(i18n, downpaymentDiscount, 0);

        return (
          <ProductOptionCard
            downpaymentDiscount={index !== 0}
            desc={isCVariant || index === 0 ? `` : `- ${monthlyDiscount}`}
            descComponent={
              index !== 0 &&
              isCVariant && (
                <Badge
                  label={i18n.formatMessage({
                    id: 'pdp.downPayment.optionDescriptionWith',
                  })}
                  className="mb-1 mt-2"
                />
              )
            }
            key={amount}
            disabled={disabled}
            isSelected={amount === selectedAmount}
            title={getFormattedPrice(i18n, amount)}
            onClick={() =>
              onOptionClick({ withDownpayment: amount !== 0, amount })
            }
          />
        );
      })}
    </div>
  );
};

export const DownPaymentExperiment: React.FC = () => {
  const classes = useStyles();
  const i18n = useIntl();
  const { isDownPaymentEnabled } = useDownPayment();
  const {
    setDownPayment,
    setDownPaymentAmount,
    downPaymentAmount,
    downPaymentEnabled,
  } = useConfigureStore();

  const handleOptionClick = (option: DownPaymentOption) => {
    setDownPaymentAmount(option.amount);
    setDownPayment(option.withDownpayment);
    trackEvent('DownPayment Selected', {
      amount: option.amount,
      downPayment: option.withDownpayment,
    });
  };

  if (!isDownPaymentEnabled || !downPaymentEnabled) return null;

  return (
    <div className={classes.root} translate="no">
      <Header i18n={i18n} />
      <OptionList
        options={downPaymentOptions}
        selectedAmount={downPaymentAmount}
        onOptionClick={handleOptionClick}
      />
    </div>
  );
};
