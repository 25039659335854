import _define_property from "@swc/helpers/src/_define_property.mjs";
export var CountryCode;
(function(CountryCode) {
    CountryCode["US"] = "US";
    CountryCode["DE"] = "DE";
})(CountryCode || (CountryCode = {}));
export var Locale;
(function(Locale) {
    Locale["ENGLISH_USA"] = "en-US";
    Locale[// TODO enable support for it
    "ENGLISH_GERMANY"] = "en-DE";
    Locale["GERMAN_GERMANY"] = "de-DE";
    Locale["FAKE_DEFAULT"] = "default";
})(Locale || (Locale = {}));
export var DefaultLocale = Locale.GERMAN_GERMANY;
export var FakeDefaultLocale = Locale.FAKE_DEFAULT;
export var isLocaleSupported = function(locale) {
    return Object.values(Locale).some(function(value) {
        return value === locale;
    });
};
var _obj;
export var DATE_FORMAT_MAPPINGS_LONG = (_obj = {}, _define_property(_obj, Locale.GERMAN_GERMANY, "dddd, DD.MM.YYYY"), // TODO en_DE support
// [Locale.ENGLISH_GERMANY]: 'DD/MM/YYYY',
_define_property(_obj, Locale.ENGLISH_USA, "dddd, MM/DD/YYYY"), _obj);
var _obj1;
export var DATE_FORMAT_MAPPINGS = (_obj1 = {}, _define_property(_obj1, Locale.GERMAN_GERMANY, "DD.MM.YYYY"), // TODO en_DE support
// [Locale.ENGLISH_GERMANY]: 'DD/MM/YYYY',
_define_property(_obj1, Locale.ENGLISH_USA, "MM/DD/YYYY"), _obj1);
var _obj2;
export var DATE_FORMAT_LABELS = (_obj2 = {}, _define_property(_obj2, Locale.GERMAN_GERMANY, "TT.MM.JJJJ"), // TODO en_DE support
// [Locale.ENGLISH_GERMANY]: 'DD/MM/YYYY',
_define_property(_obj2, Locale.ENGLISH_USA, "MM/DD/YYYY"), _obj2);
var _obj3;
export var DATE_FORMAT_MASKS = (_obj3 = {}, _define_property(_obj3, Locale.GERMAN_GERMANY, "__.__.____"), // TODO en_DE support
// [Locale.ENGLISH_GERMANY]: '__/__/____',
_define_property(_obj3, Locale.ENGLISH_USA, "__/__/____"), _obj3);
