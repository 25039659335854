export var ModalKey;
(function(ModalKey) {
    ModalKey["NOTIFY_ME"] = "NOTIFY_ME";
    ModalKey["DIRECT_CHECKOUT_CONFIRMATION_PDP"] = "DIRECT_CHECKOUT_CONFIRMATION_PDP";
    ModalKey["DEV_MODAL"] = "DEV_MODAL";
    ModalKey["FAKEDOOR_MODAL"] = "FAKEDOOR_MODAL";
    ModalKey["CONTINUOUS_AVAILABILITY"] = "CONTINUOUS_AVAILABILITY";
    ModalKey["JOBAUTO_WHITEPAPER_MODAL"] = "WHITEPAPER_MODAL";
    ModalKey["CAR_NOT_AVAILABLE"] = "CAR_NOT_AVAILABLE";
    ModalKey["CAR_DETAILS"] = "CAR_DETAILS";
    ModalKey["DELIVERY_FEE"] = "DELIVERY_FEE";
    ModalKey["PAYMENT_DATA_INFO"] = "PAYMENT_DATA_INFO";
    ModalKey["PREQUALIFY"] = "PREQUALIFY";
    ModalKey["EMPTY_CART"] = "EMPTY_CART";
    ModalKey["RETURN_TO_CHECKOUT"] = "RETURN_TO_CHECKOUT";
    ModalKey["LOGIN"] = "LOGIN";
    ModalKey["BUSINESS_ACCOUNT_DETECTED"] = "BUSINESS_ACCOUNT_DETECTED";
    ModalKey["CONFIGURE_VEHICLE"] = "CONFIGURE_VEHICLE";
    ModalKey["DATE_PICKER_MODAL"] = "DATE_PICKER_MODAL";
    ModalKey["RETENTION_MODAL"] = "RETENTION_MODAL";
    ModalKey["PROLONGATION_MODAL"] = "PROLONGATION_MODAL";
    ModalKey["INFO_MODAL"] = "INFO_MODAL";
    ModalKey["HOW_LOYALTY_WORKS"] = "HOW_LOYALTY_WORKS";
    ModalKey["PDP_CAR_INFO"] = "PDP_CAR_INFO";
})(ModalKey || (ModalKey = {}));
export var ModalHeaderVariant;
(function(ModalHeaderVariant) {
    ModalHeaderVariant["SMALL"] = "SMALL";
})(ModalHeaderVariant || (ModalHeaderVariant = {}));
export var MODAL_UNMOUNT_DELAY_MS = 250;
