"use strict";
// put your branch name and desired value for that branch for an env variable
// usage: replace all process.env.VAR_NAME_HERE with getBranchAwareEnv('VAR_NAME_HERE')
var envBranchMap = {
    PRODUCT_API_URL: {
        example_branch: "https://dev-product-api.finn.com",
        "feat/UA-9999-test": "https://staging-product-api.finn.com"
    },
    FLEET_API_URL: {
        example_branch: "https://legacy-api-fleet.finn.auto",
        "feat/UA-9999-test": "https://legacy-api-fleet.finn.auto"
    },
    HANDOVER_API_URL: {
        example_branch: "https://dev-api-car-handover.finn.auto",
        "feat/UA-9999-test": "https://dev-api-car-handover.finn.auto"
    },
    B2B_PRODUCT_API_URL: {
        example_branch: "https://dev-product-api.finn.com/b2b"
    },
    CHECKOUT_API_URL: {
        example_branch: "https://preview-158-api-checkout.finn.auto",
        "feat/UA-9999-test": "https://dev-api-checkout.finn.auto"
    },
    AUTH_API_URL: {
        example_branch: "https://dev--auth-new.netlify.app",
        "feat/UA-9999-test": "https://dev--auth-new.netlify.app"
    },
    LOYALTY_API_URL: {
        example_branch: "https://dev-api-loyalty.finn.auto"
    },
    LOYALTY_API_KEY: {},
    CP_DEPLOY_PREVIEW_URL: {
        example_branch: "https://portal.finn.auto"
    },
    VOUCHER_API_URL: {
        example_branch: "https://dev-api-voucher.finn.auto",
        "feat/UA-9999-test": "https://dev-api-voucher.finn.auto"
    },
    CUSTOMER_SELF_SERVICE_API_URL: {
        example_branch: "https://stg-customer-self-service.finn.auto",
        "feat/UA-5287-auto-trigger-swap-when-only-option": "https://customer-self-service.finn.auto"
    },
    SUBSCRIPTIONS_BASE_URL: {
        example_branch: "https://stg-api-ops-subscriptions.finn.auto",
        "feat/UA-5287-auto-trigger-swap-when-only-option": "https://api-ops-subscriptions.finn.auto"
    },
    CAR_SERVICE_BASE_URL: {
        example_branch: "https://stg-api-ops-cars.finn.auto",
        "feat/UA-5287-auto-trigger-swap-when-only-option": "https://api-ops-cars.finn.auto"
    },
    // can't hardcode secret keys here for security reasons.
    // instead will use this switch to pick a different env var saved in parameter store
    // current these 2 variables are overridden: SUBSCRIPTION_SERVICE_KEY, CAR_SERVICE_KEY with SUBSCRIPTION_SERVICE_KEY_PROD_KEY, CAR_SERVICE_KEY_PROD_KEY
    USE_PROD_KEYS: {
        example_branch: "yes",
        "feat/UA-5287-auto-trigger-swap-when-only-option": "yes"
    }
};
var getAltVarName = function(varName) {
    switch(varName){
        case "CUSTOMER_PORTAL_URL":
            return "CP_DEPLOY_PREVIEW_URL";
        default:
            return varName;
    }
};
var getBranchAwareEnv = function(varName) {
    var ref, ref1;
    // CI_COMMIT_REF_NAME for CI build BRANCH for netlify build
    var branchName = process.env.CI_COMMIT_REF_NAME || process.env.BRANCH;
    var altVarName = getAltVarName(varName);
    var branchAwareVar = (envBranchMap === null || envBranchMap === void 0 ? void 0 : (ref = envBranchMap[altVarName]) === null || ref === void 0 ? void 0 : ref[branchName]) || (envBranchMap === null || envBranchMap === void 0 ? void 0 : (ref1 = envBranchMap[varName]) === null || ref1 === void 0 ? void 0 : ref1[branchName]);
    return branchAwareVar || process.env[altVarName] || process.env[varName];
};
module.exports = {
    getBranchAwareEnv: getBranchAwareEnv
};
