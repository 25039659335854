"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
var _objectSpread = require("@swc/helpers/lib/_object_spread.js").default;
// Source https://www.figma.com/design/KUvHhOhlCXF8FbspinF2Z6/Components?node-id=3748-707&t=zpG5z0AoXoOrfh6k-4
// last sync: 08.09.2024
// This file is JS as we import it in tailwind.config.js
/** @type {import('tailwindcss').Config} */ var lineHeight = {
    xs: "1rem",
    sm: "1.375rem",
    base: "1.5rem",
    lg: "2rem",
    xl: "2.25rem",
    "2xl": "2.5rem",
    "3xl": "2.75rem",
    "4xl": "3rem",
    "5xl": "3.5rem",
    "6xl": "4rem"
};
// we set font with default line height for each size
var fontSize = {
    xs: [
        "0.75rem",
        lineHeight.xs
    ],
    sm: [
        "0.875rem",
        lineHeight.sm
    ],
    base: [
        "1rem",
        lineHeight.base
    ],
    lg: [
        "1.5rem",
        lineHeight.lg
    ],
    xl: [
        "1.75rem",
        lineHeight.xl
    ],
    "2xl": [
        "2rem",
        lineHeight["2xl"]
    ],
    "3xl": [
        "2.25rem",
        lineHeight["3xl"]
    ],
    "4xl": [
        "2.5rem",
        lineHeight["4xl"]
    ],
    "5xl": [
        "3rem",
        lineHeight["5xl"]
    ],
    "6xl": [
        "3.5rem",
        lineHeight["6xl"]
    ]
};
var fontWeight = {
    light: 300,
    regular: 400,
    medium: 500,
    semibold: 600
};
var fontFamily = {
    suisse: [
        "Suisse Intl",
        "sans-serif"
    ],
    inter: [
        "Inter",
        "sans-serif"
    ]
};
var fontVerticalTrim = {
    "6xl": {
        "&:before": {
            content: '""',
            marginBottom: "-0.2164em",
            display: "table"
        },
        "&:after": {
            content: '""',
            marginTop: "-0.2014em",
            display: "table"
        }
    },
    "5xl": {
        "&:before": {
            content: '""',
            marginBottom: "-0.1667em",
            display: "table"
        },
        "&:after": {
            content: '""',
            marginTop: "-0.1517em",
            display: "table"
        }
    },
    "4xl": {
        "&:before": {
            content: '""',
            marginBottom: "-0.245em",
            display: "table"
        },
        "&:after": {
            content: '""',
            marginTop: "-0.23em",
            display: "table"
        }
    },
    "3xl": {
        "&:before": {
            content: '""',
            marginBottom: "-0.2561em",
            display: "table"
        },
        "&:after": {
            content: '""',
            marginTop: "-0.2411em",
            display: "table"
        }
    },
    "2xl": {
        "&:before": {
            content: '""',
            marginBottom: "-0.27em",
            display: "table"
        },
        "&:after": {
            content: '""',
            marginTop: "-0.255em",
            display: "table"
        }
    },
    xl: {
        "&:before": {
            content: '""',
            marginBottom: "-0.2879em",
            display: "table"
        },
        "&:after": {
            content: '""',
            marginTop: "-0.2729em",
            display: "table"
        }
    },
    lg: {
        "&:before": {
            content: '""',
            marginBottom: "-0.3117em",
            display: "table"
        },
        "&:after": {
            content: '""',
            marginTop: "-0.2967em",
            display: "table"
        }
    },
    base: {
        "&:before": {
            content: '""',
            marginBottom: "-0.375em",
            display: "table"
        },
        "&:after": {
            content: '""',
            marginTop: "-0.375em",
            display: "table"
        }
    },
    sm: {
        "&:before": {
            content: '""',
            marginBottom: "-0.3571em",
            display: "table"
        },
        "&:after": {
            content: '""',
            marginTop: "-0.3571em",
            display: "table"
        }
    },
    xs: {
        "&:before": {
            content: '""',
            marginBottom: "-0.3333em",
            display: "table"
        },
        "&:after": {
            content: '""',
            marginTop: "-0.3333em",
            display: "table"
        }
    }
};
var typography = function() {
    return {
        // web titles
        ".web-t1-semibold": _objectSpread({
            fontSize: fontSize["6xl"][0],
            lineHeight: lineHeight["6xl"],
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.suisse.join(", ")
        }, fontVerticalTrim["6xl"]),
        ".web-t2-semibold": _objectSpread({
            fontSize: fontSize["5xl"][0],
            lineHeight: lineHeight["5xl"],
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.suisse.join(", ")
        }, fontVerticalTrim["5xl"]),
        ".web-t3-semibold": _objectSpread({
            fontSize: fontSize["4xl"][0],
            lineHeight: lineHeight["4xl"],
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.suisse.join(", ")
        }, fontVerticalTrim["4xl"]),
        ".web-t3-light": _objectSpread({
            fontSize: fontSize["4xl"][0],
            lineHeight: lineHeight["4xl"],
            fontWeight: fontWeight.light,
            fontFamily: fontFamily.suisse.join(", ")
        }, fontVerticalTrim["4xl"]),
        ".web-t4-semibold": _objectSpread({
            fontSize: fontSize["2xl"][0],
            lineHeight: lineHeight["2xl"],
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.suisse.join(", ")
        }, fontVerticalTrim["2xl"]),
        ".web-t4-light": _objectSpread({
            fontSize: fontSize["2xl"][0],
            lineHeight: lineHeight["2xl"],
            fontWeight: fontWeight.light,
            fontFamily: fontFamily.suisse.join(", ")
        }, fontVerticalTrim["2xl"]),
        // mobile titles
        ".mobile-t1-semibold": _objectSpread({
            fontSize: fontSize["4xl"][0],
            lineHeight: lineHeight["4xl"],
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.suisse.join(", ")
        }, fontVerticalTrim["4xl"]),
        ".mobile-t2-semibold": _objectSpread({
            fontSize: fontSize["3xl"][0],
            lineHeight: lineHeight["3xl"],
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.suisse.join(", ")
        }, fontVerticalTrim["3xl"]),
        ".mobile-t3-semibold": _objectSpread({
            fontSize: fontSize["2xl"][0],
            lineHeight: lineHeight["2xl"],
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.suisse.join(", ")
        }, fontVerticalTrim["2xl"]),
        ".mobile-t3-light": _objectSpread({
            fontSize: fontSize["2xl"][0],
            lineHeight: lineHeight["2xl"],
            fontWeight: fontWeight.light,
            fontFamily: fontFamily.suisse.join(", ")
        }, fontVerticalTrim["2xl"]),
        ".mobile-t4-semibold": _objectSpread({
            fontSize: fontSize.xl[0],
            lineHeight: lineHeight.xl,
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.suisse.join(", ")
        }, fontVerticalTrim.xl),
        ".mobile-t4-light": _objectSpread({
            fontSize: fontSize.xl[0],
            lineHeight: lineHeight.xl,
            fontWeight: fontWeight.light,
            fontFamily: fontFamily.suisse.join(", ")
        }, fontVerticalTrim.xl),
        // global titles
        ".global-t5-semibold": _objectSpread({
            fontSize: fontSize.lg[0],
            lineHeight: lineHeight.lg,
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.suisse.join(", ")
        }, fontVerticalTrim.lg),
        ".global-t5-light": _objectSpread({
            fontSize: fontSize.lg[0],
            lineHeight: lineHeight.lg,
            fontWeight: fontWeight.light,
            fontFamily: fontFamily.suisse.join(", ")
        }, fontVerticalTrim.lg),
        ".global-t6-semibold": _objectSpread({
            fontSize: fontSize.base[0],
            lineHeight: lineHeight.base,
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.suisse.join(", ")
        }, fontVerticalTrim.base),
        // large body
        ".body-16-light": _objectSpread({
            fontSize: fontSize.base[0],
            lineHeight: lineHeight.base,
            fontWeight: fontWeight.light,
            fontFamily: fontFamily.inter.join(", ")
        }, fontVerticalTrim.base),
        ".body-16-regular": _objectSpread({
            fontSize: fontSize.base[0],
            lineHeight: lineHeight.base,
            fontWeight: fontWeight.regular,
            fontFamily: fontFamily.inter.join(", ")
        }, fontVerticalTrim.base),
        ".body-16-medium": _objectSpread({
            fontSize: fontSize.base[0],
            lineHeight: lineHeight.base,
            fontWeight: fontWeight.medium,
            fontFamily: fontFamily.inter.join(", ")
        }, fontVerticalTrim.base),
        ".body-16-semibold": _objectSpread({
            fontSize: fontSize.base[0],
            lineHeight: lineHeight.base,
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.inter.join(", ")
        }, fontVerticalTrim.base),
        // medium body
        ".body-14-light": _objectSpread({
            fontSize: fontSize.sm[0],
            lineHeight: lineHeight.sm,
            fontWeight: fontWeight.light,
            fontFamily: fontFamily.inter.join(", ")
        }, fontVerticalTrim.sm),
        ".body-14-regular": _objectSpread({
            fontSize: fontSize.sm[0],
            lineHeight: lineHeight.sm,
            fontWeight: fontWeight.regular,
            fontFamily: fontFamily.inter.join(", ")
        }, fontVerticalTrim.sm),
        ".body-14-medium": _objectSpread({
            fontSize: fontSize.sm[0],
            lineHeight: lineHeight.sm,
            fontWeight: fontWeight.medium,
            fontFamily: fontFamily.inter.join(", ")
        }, fontVerticalTrim.sm),
        ".body-14-semibold": _objectSpread({
            fontSize: fontSize.sm[0],
            lineHeight: lineHeight.sm,
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.inter.join(", ")
        }, fontVerticalTrim.sm),
        // small body
        ".body-12-light": _objectSpread({
            fontSize: fontSize.xs[0],
            lineHeight: lineHeight.xs,
            fontWeight: fontWeight.light,
            fontFamily: fontFamily.inter.join(", ")
        }, fontVerticalTrim.xs),
        ".body-12-regular": _objectSpread({
            fontSize: fontSize.xs[0],
            lineHeight: lineHeight.xs,
            fontWeight: fontWeight.regular,
            fontFamily: fontFamily.inter.join(", ")
        }, fontVerticalTrim.xs),
        ".body-12-medium": _objectSpread({
            fontSize: fontSize.xs[0],
            lineHeight: lineHeight.xs,
            fontWeight: fontWeight.medium,
            fontFamily: fontFamily.inter.join(", ")
        }, fontVerticalTrim.xs),
        ".body-12-semibold": _objectSpread({
            fontSize: fontSize.xs[0],
            lineHeight: lineHeight.xs,
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.inter.join(", ")
        }, fontVerticalTrim.xs),
        // links
        ".link-16": _objectSpread({
            fontSize: fontSize.base[0],
            lineHeight: lineHeight.base,
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.inter.join(", "),
            textDecoration: "underline"
        }, fontVerticalTrim.base),
        ".link-14": _objectSpread({
            fontSize: fontSize.sm[0],
            lineHeight: lineHeight.sm,
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.inter.join(", "),
            textDecoration: "underline"
        }, fontVerticalTrim.sm),
        ".link-12": _objectSpread({
            fontSize: fontSize.xs[0],
            lineHeight: lineHeight.xs,
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.inter.join(", "),
            textDecoration: "underline"
        }, fontVerticalTrim.xs),
        // buttons
        ".button-16": _objectSpread({
            fontSize: fontSize.base[0],
            lineHeight: lineHeight.base,
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.inter.join(", ")
        }, fontVerticalTrim.base),
        ".button-14": _objectSpread({
            fontSize: fontSize.sm[0],
            lineHeight: lineHeight.sm,
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.inter.join(", ")
        }, fontVerticalTrim.sm),
        ".button-12": _objectSpread({
            fontSize: fontSize.xs[0],
            lineHeight: lineHeight.xs,
            fontWeight: fontWeight.semibold,
            fontFamily: fontFamily.inter.join(", ")
        }, fontVerticalTrim.xs)
    };
};
module.exports = {
    fontSize: fontSize,
    fontWeight: fontWeight,
    fontFamily: fontFamily,
    typography: typography
};
