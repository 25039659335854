import {
  createStyles,
  Grid,
  makeStyles,
  Switch,
  Theme,
  withStyles,
} from '@material-ui/core';
import useHotkeys from '@reecelucas/react-use-hotkeys';
import cn from 'classnames';
import React from 'react';

// TODO design-system: refactor !important styles after migration to new typography APP-1443

const switchStyle = (theme: Theme) =>
  createStyles({
    root: {
      width: 49,
      height: 31,
      padding: 0,
    },
    switchBase: {
      padding: 2,
      '&$checked': {
        transform: 'translateX(18px)',
        '& + $track': {
          opacity: 1,
          border: 'none',
        },
      },
    },
    thumb: {
      width: 27,
      height: 27,
      boxShadow: 'none',
    },
    track: {
      borderRadius: 16,
      opacity: 1,
      transition: theme.transitions.create(['background-color', 'border']),
    },
    checked: {},
  });

const CustomSwitch = withStyles(switchStyle)(Switch);

type Props = {
  text: string;
  hotkey: string;
  helperText?: string;
  onClick?(value: any): void;
  isActive?: boolean;
};

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      width: 'auto',
      padding: theme.spacing(1, 0),
    },
  },
}));

const DevModalSwitch: React.FC<Props> = ({
  text,
  hotkey,
  helperText,
  onClick,
  isActive,
}) => {
  const classes = useStyles();
  useHotkeys(hotkey, onClick);

  return (
    <Grid
      container
      alignItems="center"
      component="label"
      className={cn(classes.root, 'md:hover:bg-cotton')}
      onClick={onClick}
    >
      <Grid
        item
        xs
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <p className="body-14-regular pr-8">{text}</p>
        {(helperText || hotkey) && (
          <p className="body-14-regular text-steel mt-1 pr-8">
            {helperText || hotkey}
          </p>
        )}
      </Grid>
      <Grid item xs="auto">
        <CustomSwitch
          checked={Boolean(isActive)}
          value="checkedA"
          color="default"
          classes={{
            checked: '!text-white',
            track: cn({ '!bg-black': isActive, '!bg-pewter': !isActive }),
            thumb: '!bg-white',
          }}
        />
      </Grid>
    </Grid>
  );
};
export default DevModalSwitch;
