import _async_to_generator from "@swc/helpers/src/_async_to_generator.mjs";
import _object_spread from "@swc/helpers/src/_object_spread.mjs";
import _sliced_to_array from "@swc/helpers/src/_sliced_to_array.mjs";
import regeneratorRuntime from "/home/runner/work/finn-auto-ui-frontend/finn-auto-ui-frontend/node_modules/next/dist/compiled/regenerator-runtime/runtime.js";
import { TrackingEventName, useTrackingStore } from "@finn/ua-tracking";
import { isMobileApp, useSession } from "@finn/ui-utils";
import { signIn } from "next-auth/client";
import { useEffect, useState } from "react";
// this is public and doesn't change based in evn so hardcoding as constant
var GoogleClientId = "88912289849-93l2n1lk8vsuub4pf0b99hu27bujsvtr.apps.googleusercontent.com";
var maxRetryCount = 5;
export var useOneTapSignIn = function(opt) {
    var ref = _sliced_to_array(useSession(), 1), session = ref[0];
    var isGuest = session === null;
    var parentContainerId = (opt || {}).parentContainerId;
    var track = useTrackingStore(function(state) {
        return state.track;
    });
    var ref1 = useState(false), isLoading = ref1[0], setIsLoading = ref1[1];
    var ref2 = useState(0), retryCount = ref2[0], setRetryCount = ref2[1];
    useEffect(function() {
        if (isMobileApp()) {
            return;
        }
        if (!isLoading && isGuest && retryCount < maxRetryCount) {
            var google = window.google;
            if (google) {
                google.accounts.id.initialize({
                    client_id: GoogleClientId,
                    callback: function() {
                        var _ref = _async_to_generator(regeneratorRuntime.mark(function _callee(response) {
                            return regeneratorRuntime.wrap(function _callee$(_ctx) {
                                while(1)switch(_ctx.prev = _ctx.next){
                                    case 0:
                                        setIsLoading(true);
                                        track(TrackingEventName.LOGIN_METHOD_CLICKED, {
                                            additionalProps: {
                                                loginMethod: "googleonetap"
                                            }
                                        });
                                        _ctx.next = 4;
                                        return signIn("googleonetap", _object_spread({
                                            credential: response.credential,
                                            redirect: true
                                        }, opt));
                                    case 4:
                                        setIsLoading(false);
                                    case 5:
                                    case "end":
                                        return _ctx.stop();
                                }
                            }, _callee);
                        }));
                        return function(response) {
                            return _ref.apply(this, arguments);
                        };
                    }(),
                    prompt_parent_id: parentContainerId
                });
                // Here we track some error situations and reason why the Google one tap is not displayed.
                google.accounts.id.prompt(function(notification) {
                    if (notification.isNotDisplayed()) {
                        track(TrackingEventName.GOOGLE_ONE_TAP_LOGIN_MESSAGE, {
                            additionalProps: {
                                message: notification.getNotDisplayedReason()
                            }
                        });
                    } else if (notification.isSkippedMoment()) {
                        track(TrackingEventName.GOOGLE_ONE_TAP_LOGIN_MESSAGE, {
                            additionalProps: {
                                message: notification.getSkippedReason()
                            }
                        });
                    } else if (notification.isDismissedMoment()) {
                        track(TrackingEventName.GOOGLE_ONE_TAP_LOGIN_MESSAGE, {
                            additionalProps: {
                                message: notification.getDismissedReason()
                            }
                        });
                    }
                });
            } else {
                setTimeout(function() {
                    setRetryCount(function(prev) {
                        return prev + 1;
                    });
                }, 2000);
            }
        }
    }, [
        isLoading,
        isGuest,
        opt,
        parentContainerId,
        retryCount
    ]);
    return {
        isLoading: isLoading
    };
};
